import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { Authenticator } from "@aws-amplify/ui-react"

import "./i18n"
import App from "./App"
import "./index.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/exo-2"

const rootDiv = document.getElementById("root")
if (rootDiv !== null) {
   createRoot(rootDiv).render(
      <StrictMode>
         <Authenticator.Provider>
            <App />
         </Authenticator.Provider>
      </StrictMode>,
   )
} else {
   console.error("no div with id root")
}
