import { Box, CircularProgress, LinearProgress, Typography } from "@mui/material"
import { Fragment, FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

export const Loading: FunctionComponent<unknown> = props => {
   const { t } = useTranslation()
   return (
      <Fragment>
         <Box
            m={5}
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               flexGrow: "1",
               flexDirection: "column",
            }}
         >
            <Typography variant="h5">{t("LoadingComponent_Loading")}</Typography>
            <LinearProgress
               color="primary"
               sx={{
                  width: "100%",
               }}
            />
         </Box>
      </Fragment>
   )
}
